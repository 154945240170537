.secondHeadBack {
  display: none;
}

/* Define color globaly */
:root {
  --blueDark: #264876;
  --blueLight: #A8DADC;
  --blueBeige: #F1FAEE;
  --red: #C30825;
  --lightGray: #F4F4F4;
  --midGray: #979797;
  --brown: #1F1D1D;
  --gray: #808080;
}

.App {
  text-align: center;
  overflow: hidden;
}

#afterHeaderWrapper,
#afterHeaderWrapperNoDelay {
  margin-bottom: 100px;
  overflow: hidden;
}

#afterHeaderWrapperNoDelay {
  animation: fadeIn 1s 2.5s backwards;
}

#afterHeaderWrapperNoDelay {
  animation: fadeIn 1s backwards;
}

/* Logo animation start */
.firstLogo {
  animation: fadeOut 500ms 2500ms forwards;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 10;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.firstLogo img {
  height: 80vh;
  object-fit: contain;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* Logo animation end */

/* NavigationMenu start */
.topSectionNav {
  height: 150px;
}

/* NavigationMenu end */

/* Navigation area start */
/* Top logo start */
.logoImageShow,
.logoImageHide,
.logoImageShowMenu {
  display: flex;
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 17rem;
  z-index: 7;
}

.logoImageShow,
.logoImageShowMenu {
  animation: fadeIn 100ms forwards;
}

.logoImageHide {
  animation: fadeOut 100ms forwards;
}

/* Top logo end */

.topSection,
.topSectionNoDelay {
  width: 100vw;
  height: 56.26vw;
}

.topSection {
  animation: fadeIn 500ms 2700ms backwards;
}

.topSectionNoDelay {
  animation: fadeIn 500ms backwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navbar,
.navbarActive {
  position: fixed;
  top: 0;
  left: 0;
  height: 125px;
  z-index: 9;
  transition: 0.5s;
}

.navbar {
  z-index: 1000;
}

.hamBack,
.hamBackMenu {
  width: 90px;
  height: 90px;
  position: fixed;
  top: .7rem;
  right: 1.5rem;
}

.hamShow,
.hamHide,
.hamColor,
.hamShowMenu {
  width: 3rem;
  height: 10px;
  position: fixed;
  top: 3.2rem;
  right: 2.2rem;
  border-radius: 7px;
  background-color: var(--red);
  z-index: 11;
  transition: 500ms;
}

.hamBack:hover .hamShow,
.hamBackMenu:hover .hamShowMenu,
.hamBack:hover .hamColor {
  right: 4rem;
}

.hamHide {
  background-color: transparent;
}

.hamShow::before,
.hamShow::after,
.hamHide::before,
.hamHide::after,
.hamColor::before,
.hamColor::after,
.hamShowMenu::before,
.hamShowMenu::after {
  content: '';
  width: 3rem;
  height: 10px;
  position: fixed;
  right: 3rem;
  border-radius: 7px;
  background-color: var(--blueBeige);
  z-index: 10;
  transition: 0.5s;
}

.hamColor::after,
.hamColor::before {
  background-color: black;
}

.hamShow::before,
.hamHide::before,
.hamColor::before,
.hamShowMenu::before {
  top: 2rem;
}

.hamShow::after,
.hamColor::after,
.hamShowMenu::after {
  top: 4.3rem;
}

.hamShowMenu::before,
.hamShowMenu::after {
  background-color: black;
}

.hamHide::before {
  transform: rotate(130deg);
  top: 3rem;
  right: 3rem;
}

.hamHide::after {
  transform: rotate(-130deg);
  top: 3rem;
  right: 3rem;
}

.menuContentWrapper {
  display: flex;
  align-items: center;
  width: 100vw;
  flex-direction: column;
  overflow: hidden;
  position: fixed;
  z-index: 10;
  top: 50px;
}

.menuContent {
  position: relative;
  z-index: 9;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
}

.menuContent a {
  text-decoration: none;
}

.menuContentItem {
  font-family: 'Quicksand', sans-serif;
  font-size: 4rem;
  list-style-type: none;
  transform: translateX(-600%);
  color: var(--blueDark);
  margin: 20px 0;
  animation: slide-nav 1s forwards;
}

.menuContentItemTxt {
  transition: 0.5s;
}

.menuContentItemTxt a {
  /* color: var(--blueDark); */
  color: black;
}

.menuContentItemTxt:hover {
  color: white;
  transform: scale(1.2);
}

#itemOne {
  animation-delay: 50ms;
}

#itemTwo {
  animation-delay: 50ms;
}

#itemThree {
  animation-delay: 200ms;
}

#itemFour {
  animation-delay: 200ms;
}

#itemFive {
  animation-delay: 600ms;
}

#itemSix {
  animation-delay: 600ms;
}

@keyframes slide-nav {
  0% {
    transform: translateX(-600%);
  }

  100% {
    transform: translateX(0%);
  }
}

.Typewriter {
  font-family: 'Paytone One', sans-serif;
}

.Typewriter__cursor {
  writing-mode: vertical-rl;
  position: relative;
  top: 5px;
  left: 17px;
  color: var(--red);
  line-height: 80%;
}

.upTypeWriter {
  position: absolute;
  z-index: 7;
  color: var(--blueBeige);
  font-size: 4rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 56.26vw;
}

.upTypeWriter .Typewriter {
  width: inherit;
}

#afterHeaderWrapperNoDelay .textTypeWriter .Typewriter .Typewriter__cursor {
  left: 10px;
}

.callToActionWrapper {
  display: flex;
}

.firstButton {
  border: none;
  font-size: 1.2rem;
  margin-top: 40px;
  margin-left: 22px;
  margin-right: 22px;
  background-color: var(--red);
  padding: 10px 40px;
  border-radius: 50px;
  transition: 500ms;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.firstButton:hover {
  transform: scale(1.1);
}

.firstButton a {
  color: white;
}

.firstButton:hover a {
  text-decoration: none;
}

.firstButton img {
  width: 30px;
  margin-left: 10px;
  margin-bottom: 4px;
}

.video60Secondes iframe {
  width: 600px;
  height: 600px;
  margin-bottom: -6px;
}

.upTypeWriterContainer {
  display: flex;
  justify-content: center;
}

/* Navigation background start */
.menuBackgroundShow,
.menuBackgroundHide {
  position: fixed;
  top: 1.5rem;
  right: 1.5rem;
  /* background-image: radial-gradient(var(--blueLight), var(--blueBeige)); */
  background-image: radial-gradient(rgb(178, 197, 206), var(--blueBeige));
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  transition: transform 0.8s;
  z-index: 8;
}

.menuBackgroundShow {
  transform: scale(100);
}

.menuBackgroundHide {
  transform: scale(0);
}

/* Navigation background end */
/* Navigation area end */

/* Services section start */
.textTypeWriter {
  position: relative;
  margin-top: 100px;
  margin-bottom: 80px;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  min-height: 72px;
}

.longDescription {
  animation: fadeIn 2s;
  display: none;
}

.longDesText {
  text-align: left;
  font-size: 1.3rem;
  padding-right: 4rem;
  min-height: 10rem;
}

.plusWrapper {
  text-align: right;
  display: flex;
  justify-content: space-between;
}

.plusWrapper span {
  font-size: 1rem;
  width: 100%;
  text-align: right;
  padding-right: 10px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-content: center;
}

.plusImgWrapper {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  align-content: center;
}

.longDesText img {
  width: 45px;
  transition: .5s;
}

.longDesText img:hover {
  transform: rotate(360deg);
}

.serviceWrapper {
  padding-top: 20px;
  background-color: var(--lightGray);
  height: 350px;
  transition: 500ms;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.serviceWrapper:hover {
  background-color: var(--midGray);
  color: white;
  /* height: fit-content; */
}

#circleAndLetter {
  position: relative;
  transition: 1000ms;
  width: fit-content;
}

.serviceWrapper:hover #circleAndLetter {
  margin-left: -356px;
  margin-top: -75px;
}

.serviceWrapper:hover .longDescription {
  font-family: 'Quicksand', sans-serif;
  display: block;
  /* height: fit-content; */
  position: relative;
  /* top: -1rem; */
  padding-left: 30px;
  padding-top: 10px;
  width: 100%;
}

.circleImage,
.circleImageHover {
  width: 200px;
}

.circleImageHover {
  display: none;
}

.serviceWrapper:hover .shortDescription,
.serviceWrapper:hover .circleImage {
  display: none;
}

.serviceWrapper:hover .circleImageHover {
  display: block;
}

@keyframes circleMove {
  0% {
    top: 0;
    left: 0;
  }

  100% {
    top: -50px;
    left: -250px;
  }
}

/* 
.letterInCircle {
  position: relative;
  top: -10.2rem;
  left: -.1rem;
  font-size: 5rem;
  font-weight: bold;
} */

.shortDescription {
  font-family: 'Quicksand', sans-serif;
  font-size: 3rem;
  font-weight: bold;
  animation: fadeIn 1s;
  position: relative;
  transition: 1s;
}

.shortDescription p {
  margin-bottom: 0;
}

/* Services section end */

/* Realisation start */
.queryAllRealisations {
  display: none;
}

#realisationSection {
  display: flex;
  justify-content: center;
}

.endTypeWriter {
  max-width: 80%;
  margin: 100px auto 0;
  min-height: 144px;
}

.endTypeWriter .Typewriter__cursor {
  /* margin-left: -5px; */
}

.realisationContainer {
  min-width: 1400px;
  margin-top: 200px;
}

.realisationWrapper {
  height: 720px;
  display: flex;
  margin: auto;
}

.graySection1 {
  min-width: 1200px;
}

.graySection1,
.graySection2 {
  background-color: var(--lightGray);
  height: 650px;
  padding: 20px;
}

.graySection2 {
  min-width: 1200px;
  margin-top: 300px;
  margin-bottom: 300px;
}

.realisationQuery {
  display: none;
}

.graySectionQuery {
  width: 100vw;
  background-color: var(--lightGray);
  height: 600px;
  position: absolute;
  top: 35%;
  z-index: -1;
}

#realisationTitleRotateWrapper {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 3rem;
  display: flex;
  justify-content: right;
  align-content: flex-start;
  position: sticky;
  top: 20px;
  max-height: fit-content;
}

.realisationAndTitle {
  display: flex;
  max-width: inherit;
}

#realisationTitleRotateWrapper p {
  margin: 0 0 0 30px;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  height: fit-content;
}

#forSticky {
  max-height: 1420px;
  position: sticky;
  margin-right: 30px;
}

.realisationImage,
.realisationPageImage {
  width: 450px;
}

.realisation,
.realisationPage {
  overflow: hidden;
  width: 450px;
  height: 450px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-bottom: 60px;
  border-style: solid;
  border-color: var(--lightGray);
  border-width: 1px;
}

.realisation3 {
  width: fit-content;
  margin: auto;
}

.realisationText,
.realisationPageText {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  width: 450px;
  height: 450px;
  background-color: rgb(0, 0, 0, .7);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 30px;
  transition: 1s;
}

.realisation:hover .realisationText,
.realisationPage:hover .realisationPageText {
  margin-top: -450px;
  transition: 1s;
}

.realisationDes,
.realisationPageDes {
  font-size: 2rem;
}

@keyframes textSlideUp {
  0% {
    margin: 0 auto 0 60px;
  }

  100% {
    margin-top: -450px;
  }
}

.decouvrirBtn {
  margin-top: 30px;
  font-weight: bold;
}

.realisationRow {
  margin-top: -110px;
}

.realisationTitleQuery {
  font-family: 'Quicksand', sans-serif;
  font-weight: bold;
  font-size: 2.3rem;
}

.realisationTitleQuery p {
  margin-bottom: 3rem;
}

.dialogWrapper {
  /* background-color: var(--blueLight); */
  background-color: rgb(178, 197, 206);
  padding-bottom: 30px;
}

.dialogFirstDesc {
  min-height: fit-content;
}

.dialogImg {
  margin-top: 50px;
  margin-bottom: 50px;
}

.css-10ghrmp-MuiPaper-root-MuiAppBar-root {
  background-color: white !important;
  color: black !important;
  text-align: center !important;
}

.clipPlayerSite {
  display: flex;
  justify-content: center;
}

.dialogDescription {
  width: 80%;
  margin: 0 auto 20px;
  font-size: 1rem;
  font-family: 'Quicksand', sans-serif;
}

.dialogDescriptionTitle {
  font-weight: bold;
  margin-bottom: 0;
}

.dialogTitle {
  width: fit-content;
  margin: 20px auto;
  font-family: 'Paytone One', sans-serif;
}

.dialogSubtitle {
  text-align: center;
  border-top: solid 1px var(--midGray);
  font-family: 'Quicksand', sans-serif;
  margin: 20px auto 0px;
}

.accordionTxt {
  color: var(--red);
  font-weight: bold;
  font-size: 1.5rem;
}

.card-header {
  background-color: var(--lightGray);
}

.accordion .btn {
  width: 100%;
}

.accordion .btn:hover {
  color: var(--red);
}

.categoryImg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--red);
  font-weight: bold;
  margin-bottom: 15px;
  margin-right: 30px;
}

.categoryImg img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.form-group label {
  color: var(--midGray);
  font-size: 0.8rem !important;
}

.css-i4bv87-MuiSvgIcon-root {
  z-index: 1;
}

.css-8je8zh-MuiTouchRipple-root {
  z-index: 0;
  border-radius: 50%;
  background-color: var(--lightGray);
}

#realisationEnding,
#realisationEndingQuery {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 50px;
}

#realisationEnding {
  margin-top: 500px;
}

.arrowWrapper {
  position: relative;
  top: 20px;
  left: -10px;
  display: flex;
  width: fit-content;
  margin: 20px auto;
}

.arrowWrapper span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 5px solid var(--red);
  border-right: 5px solid var(--red);
  transform: rotate(-45deg);
  margin: -2px;
}

.arrowWrapper:hover span {
  animation: animateArrow 1s forwards;
  transition: 1s;

}

.arrowWrapper span:nth-child(2) {
  animation-delay: 0.2s;
}

.arrowWrapper span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes animateArrow {
  0% {
    opacity: 1;
    transform: rotate(-45deg) translate(0, 0);
  }

  50% {
    opacity: 0;
    transform: rotate(-45deg) translate(0, 0);
  }

  100% {
    opacity: 1;
    transform: rotate(-45deg) translate(10px, 10px);
  }
}

/* ARROW button start */
.round,
.servicesRound {
  position: relative;
  border: 3px solid var(--red);
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: auto;
  margin-top: 20px;
}

.servicesRound {
  border: 3px solid white;
  margin-right: 4rem;
}

#arrowContainer,
#servicesArrowContainer {
  width: 100%;
  cursor: pointer;
  position: relative;
  left: -2px;
}

#arrowContainer .arrow,
#servicesArrowContainer .arrow {
  left: 30%;
}

.arrow {
  position: absolute;
  bottom: 0;
  margin-left: 0px;
  width: 20px;
  height: 20px;
  background-size: contain;
  top: 16px;
}

.secondary {
  margin-left: 8px;
}

.servicesNext {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

.next {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0OTIuNyA0OTIuNyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDkyLjcgNDkyLjc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojQzMwODI1O30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTE4OS41LDkuOWMyMy40LDIzLjIsMTM2LjIsMTQ2LjcsMTkwLDIwNS43YzI4LDMzLjMsMSw2MS45LTguMSw3Mi4zQzM1NC41LDMwNi42LDIyNy45LDQ0MiwxOTEuNiw0ODAuNQoJYy0zMS44LDMzLjgtOTcuNy0zOC4xLTczLjQtNjQuM2MzMS4zLTMzLjgsMTYzLjMtMTY4LjYsMTYzLjMtMTY4LjZTMTUwLjEsMTAwLjcsMTIwLjIsNjguNVMxNTguNi0yMC45LDE4OS41LDkuOXoiLz4KPC9zdmc+Cg==);
}

/* ARROW button end */

@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }

  25% {
    opacity: 0;
    transform: translateX(10px) scale(0.9);
  }

  26% {
    opacity: 0;
    transform: translateX(-10px) scale(0.9);
  }

  55% {
    opacity: 1;
    transform: translateX(0px) scale(1);
  }
}

.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.arrow.primary.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.round:hover .arrow,
.servicesRound:hover .arrow {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.round:hover .arrow.primary,
.servicesRound:hover .arrow.primary {
  animation-name: bounceAlpha;
  animation-duration: 1.4s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.clientsInRealisation {
  margin-top: 50px;
}

/* Realisation end */


/* Fromations start */
.formationsSubTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  margin: 50px 0 80px;
}

.formationsSubTitle p {
  margin: 0;
}

.formationsSubTitleRed {
  color: red;
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  margin: 100px 0 50px;
}

.afterCouteau {
  margin: 50px 0;
}

.afterCouteau p {
  color: var(--gray);
  font-family: 'Quicksand', sans-serif;
  font-size: 1.7rem;
  margin: 0;
}

.sectionCouteau {
  background-color: var(--lightGray);
}

.sectionCouteau img {
  width: 50vw;
  margin: 80px 0;
}

.formationBanner {
  font-weight: bold;
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  color: white;
  background-color: black;
  padding: .8rem 0;
}

.formationLogo1 img {
  width: 12vw;
  margin: 0 1.5rem;
}

.formationLogo1,
.formationLogo2 {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.formationLogo1 {
  padding: 10px 0;
}

.formationLogo2 {
  padding: 20px 0;
}

.formationLogo2 img {
  width: 9vw;
  margin: 0 1rem;
}

/* Formations end */

/* Contact start */
#contactSection {
  height: 300px;
  background-color: var(--lightGray);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#contactLineTwo a {
  text-decoration: none;
  color: var(--red);
}

#contactLineTwo {
  margin: auto;
  width: fit-content;
  transition: 1s;
}

#contactLineTwo:hover {
  transform: scale(1.2);
}

#contactLineOne,
#contactLineTwo {
  font-size: 3rem;
  font-weight: bold;
}

/* Contact end */

/* Clients start */
.clientSection {
  height: 200px;
  padding-top: 50px;
}

.clientWrapper {
  display: flex;
  align-items: center;
  width: 450px;
  height: 200px;
}

.clientImage {
  width: 160px;
  transition: 700ms;
  position: relative;
  z-index: 10000;
}

.clientImage:hover {
  transform: scale(1.1);
}

.alice-carousel {
  height: 180px;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  font-size: 4rem !important;
  position: relative !important;
  top: -170px !important;
  padding: 72px 5px !important;
}

.alice-carousel__prev-btn {
  left: 0;
  text-align: left !important;
}

.alice-carousel__next-btn {
  right: 0;
  text-align: right !important;
}

.alice-carousel__prev-btn-item span::after {
  content: '❮' !important;
}

.alice-carousel__next-btn-item span::after {
  content: '❯' !important;
}

.alice-carousel__prev-btn-item span,
.alice-carousel__next-btn-item span {
  color: var(--midGray);
  transition: 400ms;
}

.alice-carousel__prev-btn-item span:hover,
.alice-carousel__next-btn-item span:hover {
  color: var(--lightGray) !important;
}

/* Clients end */

/* Associations start */
.associationSection {
  padding-top: 50px;
}

.associationCarousel {
  width: 80%;
  margin: auto;
}

.associationsTitle {
  font-size: 2rem;
  font-weight: bold;
  background-color: var(--lightGray);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.associationImage {
  width: 270px;
  transition: 700ms;
  filter: grayscale(100%);
  opacity: 0.4;
}

.associationImage:hover {
  transform: scale(1.2);
  opacity: 1;
  filter: grayscale(0);
}

.bYsOXP,
.cKsJly {
  min-width: auto !important;
}

/* Associations end */

/* Footer start */
.footerWrapper {
  background-color: var(--brown);
  color: white;
  display: flex;
  padding: 30px;
  justify-content: center;
}

.footerInner {
  width: 600px;
}

#footerLogo img {
  width: 80px;
  margin: 0 2rem;
}

.footerTxt {
  margin-top: -150px;
  margin: 1rem;
  font-family: 'Quicksand', sans-serif;
  font-size: .8rem;
}

.footerWrapper p {
  font-family: 'Quicksand', sans-serif;
  font-size: .85rem;
  margin: 0;
}

.footerTxtLeft {
  text-align: right;
}

.footerWrapper strong {
  font-size: 1.1rem;
}

.footerTxtRight {
  text-align: left;
}

.footerHide {
  display: none;
}

/* Footer end */

/* Realisation page start */
.realisationPageGray {
  background-color: var(--lightGray);
  max-width: 1700px;
  max-height: 2600px;
  margin: auto;
  margin-bottom: 200px;
}

.realisationPageForGray {
  position: relative;
  top: -100px;
}

.realisationPageMain {
  margin-top: 100px;
}

.realisationPageWrapper {
  max-width: 80%;
  margin: auto auto 50px;
}

.textTypeRealisation {
  font-size: 4rem;
  text-align: left;
  height: 180px;
  margin: 0 auto;
  max-width: 80vw;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.realisationMarginTop {
  margin-top: 60px;
}

/* Realisation page end */

/* Agence page start */
.agenceHeaderLeft {
  background-color: var(--red);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  padding: 40px;
  height: 600px;
}

.agenceHeaderLeft div {
  margin-top: 20px;
}

.agenceHeaderRight {
  font-family: 'Quicksand', sans-serif;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  text-align: left;
  background-color: black;
  color: white;
  padding: 40px;
}

.agenceHeaderRightLast {
  margin-bottom: 0;
}

.agenceTitle {
  margin: 50px auto;
  margin-top: 90px;
}

.agenceTitle h1 {
  font-family: 'Paytone One', sans-serif;
  font-size: 4rem;
  margin: 0;
}

.agenceSubTitle {
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  margin: 50px 0 80px;
}

.agenceMainSection {
  background-image: url('./images/circle.png');
  width: 1100px;
  height: 1100px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin-bottom: 80px;
}

.agenceImgRow {
  overflow: hidden;
  height: 396px;
}

.agenceImgRowUp {
  margin-bottom: 80px;
}

.agencePersonTitleAndTag {
  background-color: black;
  position: relative;
  z-index: 1;
}

.agenceImageColor:hover .agencePersonTitleAndTag {
  margin-top: -127px;
  transition: 500ms;
}

.agencePersonTag {
  color: white;
  font-family: 'Quicksand', sans-serif;
  font-size: .9rem;
  font-weight: bold;
  text-align: left;
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 30px;
}

.agenceImageBW,
.agenceImageBW img,
.agenceImageColor,
.agenceImageColor img {
  width: 280px;
}

.agenceImageBW,
.agenceImageColor {
  margin: auto;
}

.agenceImageColor {
  opacity: 0;
  transition: 500ms;
  margin-top: -397px;
}

.agenceLeftImage {
  margin-left: auto;
  margin-right: 25px;
}

.agenceRightImage {
  margin-left: 25px;
  margin-right: auto;
}

.agenceImageColor:hover {
  opacity: 100;
}

.agencePersonTitleWrapper {
  background-color: white;
  display: flex;
  text-align: left;
  padding: 10px;
  min-height: 97px;
}

.agencePersonTitleWrapper img {
  width: 30px;
}

.agencePersonTitleWrapper h3 {
  margin-bottom: 0;
  font-family: 'Quicksand', sans-serif;
}

.agencePersonTitleWrapper p {
  font-family: 'Quicksand', sans-serif;
  margin-bottom: 0;
  font-size: .9rem;
}

.agencePersonTitle {
  width: 87%;
}

.agenceEnvelopeWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agenceButtomSection {
  background-color: var(--lightGray);
  padding: 80px 0;
}

.agenceButtomSectionTitle {
  margin-bottom: 80px;
}

.agenceButtomSectionTxtWrapper {
  width: fit-content;
  margin: auto;
}

.agenceButtomSectionTxt {
  font-family: 'Quicksand', sans-serif;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.agenceButtomSectionTxt p {
  margin: 0;
}

.algegoLogoSmall {
  display: flex;
  align-items: center;
}

.algegoLogoSmall img {
  margin-right: 10px;
  width: 25px;
}

.contactUsAgence {
  margin: 50px auto !important;
}

/* Agence page end */

/* Contact page start  */
.contactImage {
  height: 500px;
  background-image: url('./images/contact-header-image.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 34.9195%;
  margin-bottom: 50px;
}

.contactImageInner {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 500px;
  background-color: rgb(31, 29, 29, .5);
  width: 100vw;
}

.contactImageInner h1 {
  font-family: 'Paytone One', sans-serif;
  font-size: 4rem;
}

.contactAddress {
  text-align: left;
  font-family: 'Quicksand', sans-serif;
  font-size: 1.2rem;
}

.contactAddress img {
  width: 25px;
  margin-right: 20px;
}

.contactAddress p {
  margin: 0;
}

.contactForm {
  text-align: left;
  font-family: 'Quicksand', sans-serif;
}

.contactForm input,
textarea {
  background-color: var(--lightGray);
  border: none;
  width: 100%;
}

.contactForm input {
  height: 50px;
  padding-left: 20px;
}

#contactMessage ::placeholder {
  position: relative;
  top: 13px
}

.contactForm textarea {
  height: 140px;
  padding-left: 20px;
}

.formInput {
  margin: 20px auto;
}

.contactUsWrapper {
  margin-bottom: 50px;
}

.formButton input {
  background-color: var(--red);
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}


.showMessage {
  display: block;
}

.hideMessage {
  display: none;
}

/* Contact page end */

/* Temoignage start */
.temoignageContainer {
  width: 1400px;
  height: 770px;
}

.temoignageGray {
  background-color: var(--lightGray);
  width: 1250px;
  margin-left: -73px;
  height: 624px;
  margin-top: 145px;
}

.temoignageForGray {
  margin-left: 51px;
  width: 1140px;
  position: relative;
  top: -72px;
}

.temoignageRow {
  margin-bottom: 40px;
}

.temoignageVideoSub {
  background-color: var(--red);
  height: 60px;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 370px;
}

.temoignageVideo {
  margin: auto;
  width: 800px;
}

.temoignageCol {
  display: flex;
  flex-direction: column;
}

.temoigangeImage {
  margin-top: -5px;
  background-color: white;
  width: fit-content;
  margin: auto;
}

.temoignageCard {
  background-color: white;
  max-width: 350px;
  margin: auto;
  border-style: solid;
  border-width: 1px;
  border-color: var(--lightGray);
}

.temoignageCardLeft {
  margin-right: 5px;
}

.temoignageCardRight {
  margin-left: 5px;
}

.temoignageSecondTitle {
  margin: 122px auto 50px;
}

.temoigangeImage img {
  max-height: 80px;
}

.temoignageGraySection {
  background-color: var(--lightGray);
  width: 1600px;
  height: 700px;
  margin: auto;
}

/* Temoigange end */


/* Not Found page */
.error {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Media query */

@media only screen and (max-height: 820px) {
  .longDesText {
    padding-top: 20px;
  }
}

@media only screen and (max-height: 660px) {
  .menuContentItemTxt {
    line-height: 1.4rem;
  }

  .menuContentItemTxt a {
    font-size: 2.5rem;
  }

  #itemOne {
    margin-top: 30px;
  }
}

@media only screen and (max-height: 550px) {
  .menuContentItemTxt {
    line-height: .6rem;
  }

  .menuContentItemTxt a {
    font-size: 2rem;
  }

  #itemOne {
    margin-top: 30px;
  }
}


@media only screen and (max-width: 1180px),
(max-height: 820px) {
  .graySectionQuery {
    height: 694px;
  }

  .menuContentItemTxt {
    font-size: 3rem;
  }

  .logoImageShow {
    width: 14rem;
  }

  .hamShow,
  .hamShow::after,
  .hamShow::before {
    width: 2.7rem;
    height: 9px;
  }

  .hamShow::before {
    top: 2.2rem;
  }

  .hamShow::after {
    top: 4.2rem;
  }
}

@media only screen and (max-width: 1180px) {
  .firstButton {
    margin-top: 30px;
    padding: 7px 50px;
  }
}


@media only screen and (max-width: 1000px),
(max-height: 800px) {
  .menuContentItem {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 1000px),
(max-height: 700px) {
  .menuContentItem {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 1000px),
(max-height: 650px) {
  .menuContentItem {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1000px),
(max-height: 600px) {
  .menuContentItem {
    font-size: 1.8rem;
  }

  .menuContent {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 1750px) {
  .realisationPageGray {
    height: 2212px;
  }

  .realisationPageImage,
  .realisationPageText,
  .realisationPage {
    width: 370px;
    min-height: 370px;
    height: 370px;
  }

  .realisationPage:hover .realisationPageText {
    margin-top: -370px;
  }
}

/* @media only screen and (max-width: 1734px) {
  .serviceWrapper:hover .longDescription {
    top: -5rem;
  }
} */

/* @media only screen and (max-width: 1652px) {
  .longDesText {
    width: 300px;
    margin-left: -50px;
    position: relative;
    top: 20px;
  }
} */

@media only screen and (max-width: 1707px) {
  .serviceWrapper:hover .longDescription {
    width: 60%;
    position: absolute;
    margin-left: 11rem;
  }
}

@media only screen and (max-width: 1600px) {
  .serviceWrapper:hover #circleAndLetter {
    margin-left: -320px;
  }

  #contactLineOne {
    max-width: 80%;
    margin: auto;
  }

  .contactImageInner h1 {
    font-size: 3rem;
  }

  .topSectionNav {
    height: 130px;
  }
}

@media only screen and (max-width:1526px) {
  .longDesText {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width:1500px) {

  .circleImage,
  .circleImageHover {
    width: 180px;
  }

  .letterInCircle {
    top: -8.5rem;
    font-size: 3.5rem;
  }

  .shortDescription {
    font-size: 2.3rem;
    margin-top: 30px;
  }

  .longDesText {
    width: 300px;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    height: inherit;
    font-size: 1.1rem;
  }

  .realisationPageGray {
    height: 1866px;
  }

  .realisationPageImage,
  .realisationPageText,
  .realisationPage {
    width: 300px;
    min-height: 300px;
    height: 300px;
  }

  .realisationPage:hover .realisationPageText {
    margin-top: -300px;
  }

  .realisationPageDes {
    font-size: 1.5rem;
  }

  .textTypeRealisation {
    font-size: 3.5rem;
    height: 130px;
  }

  .temoignageSecondTitle {
    margin-top: 160px;
  }
}

@media only screen and (max-width: 1450px) {
  .serviceWrapper:hover .longDescription {
    margin-left: 7rem;
  }

  .realisationAndTitle {
    display: block;
    max-width: fit-content;
  }

  #realisationTitleRotateWrapper p {
    writing-mode: horizontal-tb;
    transform: rotate(0);
    margin-left: 0;
  }

  #forSticky {
    position: relative;
    margin-right: 0;
    height: 200px;
    margin-top: 0;
    display: flex;
    justify-content: center;
  }

  .realisationContainer {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  #afterHeaderWrapper {
    margin-bottom: 50px;
  }

  .realisationContainer {
    min-width: 100%;
  }

  #contactLineOne,
  #contactLineTwo {
    font-size: 2.5rem;
  }

  #contactLineOne {
    max-width: 50%;
  }
}

@media only screen and (max-width: 1300px) {
  .serviceWrapper:hover .longDescription {
    margin-left: 6rem;
  }

  .longDesText {
    margin-right: 60px;
  }

  #contactLineOne,
  #contactLineTwo {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 1260px) {
  .serviceWrapper:hover .longDescription {
    margin-left: 5rem;
  }

  .agenceHeaderLeft {
    height: 621px;
  }
}

@media only screen and (max-width: 1200px) {
  .upTypeWriter {
    font-size: 3rem;
  }

  .serviceWrapper {
    height: 300px;
  }

  .realisationAll {
    margin-top: 50px;
  }

  .realisationContainer {
    display: none;
  }

  .realisationQuery {
    display: block;
  }

  .firstLogo img {
    width: 900px;
  }
}

@media only screen and (max-width:1199px) {
  .longDesText img {
    width: 38px;
  }

  .longDesText {
    margin: 0;
    width: 100%;
    position: absolute;
    top: 100px;
  }

  .longDescription {
    height: 200px;
    margin-top: 20px;
  }

  .serviceWrapper {
    margin-bottom: 30px;
  }

  .serviceWrapper:hover {
    display: flex;
    margin-top: 30px;
  }

  .secondBreakPoint {
    display: block;
  }

  .agenceHeaderLeft {
    justify-content: center;
    align-items: center;
    text-align: center;
    height: fit-content;
  }

  .agenceHeaderRight {
    padding: 50px;
  }

  .agenceImgRow {
    margin-bottom: 0;
    margin: 40px auto;
  }

  .agenceMainSection {
    width: auto;
    height: auto;
    background-size: 100%;
    background-position: center;
    width: 80vw;
  }

  .agenceButtomSection {
    width: 80vw;
    margin: auto;
  }

  .realisationPageGray {
    height: 1350px;
    margin-bottom: 120px;
  }

  .realisationPageImage,
  .realisationPageText,
  .realisationPage {
    width: 250px;
    min-height: 250px;
    height: 250px;
  }

  .realisationPage:hover .realisationPageText {
    margin-top: -250px;
  }

  .agenceLeftImage {
    margin-right: auto;
  }

  .agenceRightImage {
    margin-left: auto;
  }

  .realisationPageDes {
    font-size: 1.5rem;
  }

  .textTypeRealisation {
    height: 130px;
  }

  .realisationMarginTop {
    margin-top: 0;
  }

  .contactImageInner h1 {
    max-width: 80%;
  }

  .agenceButtomSectionTxtWrapper {
    width: 495px;
  }

  .agenceImgRow {
    background-image: url('./images/circle.png');
    background-repeat: no-repeat;
    background-size: 95%;
    background-position: center;
  }

  .agenceMainSection {
    background-image: none;
  }

  .temoignageGray {
    margin-left: -157px;
  }

  .temoignageRowUp {
    margin-bottom: 40px;
  }

  .temoignageRowUpLeft {
    margin-right: 5px;
  }

  .temoignageRowUpRight {
    margin-left: 5px;
  }

  .temoignageContainer {
    height: 1120px;
  }
}

@media only screen and (max-width:1007px) {
  .agenceTitle h1 {
    font-size: 3rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  .agenceTitle {
    margin-top: 50px;
  }
}

@media only screen and (max-width:1000px) {

  .logoImageShow,
  .logoImageHide,
  .logoImageShowMenu {
    width: 11rem;
  }

  .hamShow,
  .hamColor,
  .hamShowMenu,
  .hamColorMenu {
    height: 7px;
    width: 2.5rem;
    top: 2.8rem;
  }

  .hamShow::before,
  .hamColor::before,
  .hamShowMenu::before,
  .hamColorMenu::before {
    height: 7px;
    width: 2.5rem;
  }

  .hamShow::after,
  .hamColor::after,
  .hamShowMenu::after,
  .hamColorMenu::after {
    height: 7px;
    width: 2.5rem;
    margin-top: -11px;
  }

  .upTypeWriter {
    font-size: 2.5rem;
  }

  .firstLogo img {
    width: 700px;
  }

  .menuContentItem {
    font-size: 3rem;
  }

  .topSectionNav {
    height: 105px;
  }

  .textTypeRealisation {
    font-size: 3rem;
    height: 110px;
  }

  .firstButton {
    font-size: 1.1rem;
    padding: 7px 15px;
  }

  .firstButton img {
    width: 20px;
  }

  .temoignageSecondTitle h1 {
    font-size: 2rem;
    width: 80%;
    margin: auto;
  }
}

@media only screen and (max-width:991px) {

  .realisationText,
  .realisationImage,
  .realisation {
    width: 250px;
    height: 250px;
  }

  .realisationDes {
    font-size: 1.5rem;
  }

  .realisation:hover .realisationText {
    margin-top: -250px;
  }

  .realisationPageGray {
    height: 2280px;
  }

  .temoignageGray {
    margin-left: -276px;
  }

  .temoignageContainer {
    height: 1880px;
  }

  .temoignageRowUpLeft,
  .temoignageCardLeft {
    margin-right: auto;
  }

  .temoignageRowUpRight,
  .temoignageCardRight {
    margin-left: auto;
  }

  .temoignageCardLeft {
    margin-bottom: 40px;
  }

  .graySectionQuery {
    background-color: white;
  }

  .graySectionResponsive {
    background-color: var(--lightGray);
  }
}

@media only screen and (max-width:900px) {
  .temoignageSecondTitle {
    margin-top: 99px;
  }
}

@media only screen and (max-width:850px) {
  .temoignageSecondTitle {
    margin-top: 0;
  }

  .temoignageVideo {
    width: 90vw;
  }

  .longDescription {
    margin-left: 5rem;
    padding: 0 !important;
  }

  .longDesText {
    /* margin-top: 20px; */
    width: 453px;
  }
}

@media only screen and (max-width:820px) {
  .hamShow::before {
    top: 2.1rem;
  }
}

@media only screen and (max-width: 800px) {

  #afterHeaderWrapperNoDelay,
  #afterHeaderWrapper {
    margin-bottom: 20px;
  }

  .upTypeWriter {
    font-size: 3rem;
  }

  .firstButton {
    margin-top: 20px;
    padding: 4px 15px;
    font-size: 1rem;
  }

  .firstButton img {
    width: 20px;
    margin-top: -3px;
    margin-bottom: 0;
  }

  .textTypeWriter {
    font-size: 2.5rem;
    margin: 20px 0;
  }

  #contactLineOne {
    max-width: 80%;
  }

  #contactLineOne,
  #contactLineTwo {
    font-size: 1.7rem;
  }

  #contactSection {
    height: fit-content;
    padding: 40px 0;
  }

  .firstLogo img {
    width: 600px;
  }

  .agenceTitle h1 {
    font-size: 2.5rem;
  }

  .agenceSubTitle {
    width: 80%;
    margin: 50px auto;
  }

  .contactUsAgence {
    margin: 30px auto !important;
  }

  .textTypeRealisation {
    font-size: 2.2rem;
    height: 80px;
  }

  .formationBanner {
    font-size: 1.6rem;
  }
}

@media only screen and (max-width:767px) {
  .temoignageGray {
    margin-left: -367px;
  }

  .dialogImg {
    width: 98%;
    object-fit: contain;
  }

  .clipPlayerSite {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 716px) {
  .callToActionWrapper {
    display: block;
  }
}

@media only screen and (max-width: 700px) {
  .menuContentItem {
    font-size: 2.7rem;
  }

  .menuContentItemTxt {
    font-size: 2rem;
  }

  .realisationPageGray {
    height: 2272px;
  }

  .contactImageInner h1 {
    font-size: 2.5rem;
  }

  .realisationClient {
    font-size: .8rem;
  }
}

@media only screen and (max-width: 691px) {
  .firstButton {
    font-size: .9rem;
    padding: 8px 40px;
  }

  .firstButton img {
    width: 20px;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 660px) {
  .agenceTitle h1 {
    font-size: 2rem;
  }

  .agenceSubTitle {
    font-size: 1.5rem;
  }

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 0 !important;
  }

  .video60Secondes iframe {
    width: 95vw;
  }
}

@media only screen and (max-width:650px) {
  .agenceButtomSectionTitle h1 {
    font-size: 2rem;
  }

  .agenceButtomSection {
    padding: 40px 0;
  }

  .agenceButtomSectionTitle {
    margin-bottom: 50px;
  }

  .agenceButtomSectionTxt {
    font-size: 1.5rem;
  }

  .agenceButtomSectionTxtWrapper {
    width: 385px;
    margin: auto;
  }
}

@media only screen and (max-width:640px) {
  .upTypeWriterContainer .Typewriter {
    font-size: 2rem;
    margin-top: 50px;
  }

  .firstButton {
    font-size: .8rem;
    padding: 5px 30px;
  }

  .firstButton img {
    width: 18px;
  }
}

@media only screen and (max-width:600px) {

  #afterHeaderWrapperNoDelay .textTypeWriter .Typewriter .Typewriter__cursor {
    left: 6px;
  }

  #afterHeaderWrapperNoDelay,
  #afterHeaderWrapper {
    margin-bottom: 0;
  }

  #realisationSection {
    height: 150px;
  }

  .textTypeRealisation {
    font-size: 2rem;
    height: 80px;
  }

  .logoImageShow,
  .logoImageShowMenu {
    width: 8rem;
  }

  .hamShow,
  .hamColor,
  .hamShowMenu,
  .hamColorMenu {
    height: 5px;
    width: 2.2rem;
    top: 2rem;
  }

  .hamShow::before,
  .hamColor::before,
  .hamShowMenu::before,
  .hamColorMenu::before {
    height: 5px;
    width: 2.2rem;
    top: 1.4rem;
  }

  .hamShow::after,
  .hamColor::after,
  .hamShowMenu::after,
  .hamColorMenu::after {
    height: 5px;
    width: 2.2rem;
    top: 3.3rem;
  }

  .upTypeWriter {
    font-size: 2rem;
  }

  .firstButton {
    margin-top: 12px;
  }

  .textTypeWriter {
    font-size: 1.8rem;
    margin: 20px 0;
  }

  .shortDescription {
    font-size: 1.9rem;
  }

  .serviceWrapper {
    height: 300px;
  }

  .realisationTitleQuery {
    font-size: 1.9rem;
  }

  .firstLogo img {
    width: 400px;
  }

  .menuContentItem {
    font-size: 2.3rem;
  }

  .topSectionNav {
    height: 80px;
  }

  .logoImageShowMenu {
    top: 1.2rem;
  }

  #afterHeaderWrapper {
    margin-bottom: 20px;
  }

  .temoignageSecondTitle {
    position: relative;
    top: -100px;
  }

  .temoignageGray {
    margin-top: -25px;
  }

  .dialogTitle {
    font-size: 2rem;
  }

  .temoignageSecondTitle h1 {
    margin-top: 50px;
    font-size: 1.4rem;
  }
}


@media only screen and (max-width:599px) {
  .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root {
    width: 30px !important;
    height: 30px !important;
    margin-left: 0 !important;
  }

  .clipPlayerSite {
    margin-top: -40px;
    height: 430px;
  }

  .formationLogo1,
  .formationLogo2 {
    display: block;
  }

  .formationLogo2_2ndRow {
    margin-top: 30px;
  }
}

@media only screen and (max-width:575px) {
  .longDescription {
    margin-left: 6rem !important;
    margin-top: 0;
  }

  .longDesText {
    padding: 0 !important;
    width: 100% !important;
  }

  .agenceImgRow {
    background-size: 80%;
  }

  .footerTxtLeft,
  .footerTxtRight {
    text-align: center;
    margin: 0;
  }

  .footerHide {
    display: block;
  }

  .footerShow {
    display: none;
  }

  .temoigangeResponsive {
    width: 100vw;
    display: flex;
    justify-content: center;
    position: relative;
    left: 300px;
  }

  .clipPlayerSite {
    margin-top: -60px;
    height: 400px;
  }

  .realisationPageGray {
    display: none;
  }

  .queryAllRealisations {
    display: block;
  }

  .realisationPageMain {
    margin-top: 0;
  }

  .realisationPageWrapper {
    margin-bottom: 0;
  }

  .realisationPageImage,
  .realisationPageText,
  .realisationPage {
    width: 300px;
    min-height: 300px;
    height: 300px;
  }

  .realisationPage:hover .realisationPageText {
    margin-top: -300px;
  }

  .longDesText {
    width: 250px;
  }

  .dialogImg {
    position: relative;
    top: 10%;
  }

  .formationBanner {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width:550px) {
  .dialogImg {
    top: 5%;
  }
}

@media only screen and (max-width:542px) {
  .textTypeRealisation {
    font-size: 1.7rem;
  }

  .temoignageSecondTitle h1 {
    font-size: 1.3rem;
  }

  .temoignageSecondTitle {
    top: -120px;
  }

  .temoignageGray {
    margin-top: -65px;
  }
}


@media only screen and (max-width:530px) {
  .agenceTitle h1 {
    font-size: 1.7rem;
  }

  .agenceSubTitle {
    font-size: 1.2rem;
    margin: 30px auto;
  }
}

@media only screen and (max-width:516px) {
  .agenceHeaderLeft {
    font-size: 1.5rem;
  }

  .temoignageSecondTitle {
    top: -166px;
  }

  .temoignageGray {
    position: relative;
    left: -1.5%;
  }

  .temoigangeResponsive {
    left: 324px;
  }

  .temoignageGray {
    margin-top: -100px;
  }

  .temoignageSecondTitle h1 {
    margin-top: 100px;
  }

  .formationsSubTitle {
    margin-bottom: auto;
  }
}

@media only screen and (max-width:500px) {
  #realisationSection {
    height: 220px;
  }

  .menuContentItemTxt {
    font-size: 1.7rem;
  }

  .menuContentItem {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  #itemOne {
    margin-top: 30px;
  }

  .contactImageInner h1 {
    font-size: 2rem;
  }

  .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root {
    width: 28px !important;
    height: 28px !important;
    margin-left: 0 !important;
  }

  .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root svg {
    width: 23px !important;
    height: 23px !important;
  }

  .clipPlayerSite {
    margin-top: -70px;
    height: 380px;
  }

  .upTypeWriterContainer .Typewriter {
    font-size: 1.5rem;
  }

  .firstButton {
    padding: 3px 30px;
    font-size: .8rem;
  }

  .firstButton img {
    width: 15px;
  }
}

@media only screen and (max-width: 490px) {

  .realisationImage,
  .realisation,
  .realisationText {
    width: 350px;
    height: 350px;
  }

  .realisation:hover .realisationText {
    margin-top: -350px;
  }

  #contactLineOne,
  #contactLineTwo {
    font-size: 1.3rem;
  }

  .menuContentItem {
    font-size: 2.1rem;
  }

  .agenceImgRow {
    background-size: 100%;
  }

  .contactUsAgence {
    margin: 20px auto !important;
  }
}

@media only screen and (max-width:472px) {
  .longDesText {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 466px) {
  .textTypeRealisation {
    height: 120px;
    width: 70%;
    text-align: center;
    margin-bottom: 0;
    font-size: 1.4rem;
  }

  .textTypeRealisation .Typewriter__cursor {
    margin-right: -20px;
  }

  .temoignageSecondTitle {
    top: -218px;
  }

  .temoignageGray {
    margin-top: -150px;
  }

  .temoignageSecondTitle h1 {
    margin-top: 130px;
  }

  .realisationPageWrapper {
    height: 80px;
  }
}

@media only screen and (max-width: 450px) {
  .agenceButtomSectionTxt {
    font-size: 1.3rem;
  }

  .agenceButtomSectionTxtWrapper {
    width: 340px;
  }

  .dialogTitle {
    font-size: 1.5rem;
  }

  .clipPlayerSite {
    margin-top: -80px;
    height: 370px;
  }
}

@media only screen and (max-width: 412px) {
  .textTypeRealisation {
    height: 80px;
  }
}

@media only screen and (max-width: 410px) {

  .realisationPageImage,
  .realisationPageText,
  .realisationPage,
  .realisationImage,
  .realisationText,
  .realisation {
    width: 250px;
    min-height: 250px;
    height: 250px;
  }

  .realisationPage:hover .realisationPageText,
  .realisation:hover .realisationText {
    margin-top: -250px;
  }

  .realisationPageDes {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 400px) {
  .serviceWrapper:hover .longDescription {
    margin-left: 15rem !important;
  }

  .upTypeWriter {
    font-size: 1.5rem;
    margin: auto;
  }

  .firstLogo img {
    width: 300px;
  }

  .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root svg {
    width: 20px !important;
    height: 20px !important;
  }

  .css-1d6w9lk-MuiButtonBase-root-MuiIconButton-root {
    width: 25px !important;
    height: 25px !important;
    margin-left: -5px !important;
  }

  .css-hyum1k-MuiToolbar-root {
    min-height: 40px !important;
  }

  .clipPlayerSite {
    margin-top: -110px;
    height: 340px;
  }

  .dialogImg {
    top: 15%;
  }

  .menuContentItem {
    font-size: 1.5rem;
    margin-top: 10px;
  }

  .longDesText {
    margin-left: -80px;
  }
}

@media only screen and (max-width:390px) {
  .longDesText {
    /* margin-top: 20px; */
    font-size: .9rem;
  }

  .dialogImg {
    top: 15%;
  }

  .firstHeadBack {
    display: none;
  }

  .secondHeadBack {
    display: block;
  }

  .temoignageRowUpRight {
    margin-left: 30%;
  }

  .menuContentWrapper {
    margin-top: -50px;
  }

  .menuContentItemTxt {
    font-size: 1.2rem;
  }

  #itemOne {
    margin-top: 70px;
  }

  .agenceButtomSectionTxtWrapper {
    width: 288px;
    text-align: left;
  }

  .agenceButtomSectionTxt {
    align-items: flex-start;
    margin-top: 5px;
  }

  .algegoLogoSmall {
    margin-top: 5px;
  }
}

@media only screen and (max-width:375px) {

  .upTypeWriterContainer .Typewriter {
    font-size: 1.1rem;
  }

  .agenceImgRow {
    background-image: none;
  }
}